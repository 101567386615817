import React from 'react';
import { Meta } from 'components/Meta';
import { RouteEnum } from 'model/route.enum';
import Layout from 'components/Layout';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import 'resources/styles/pages/default.scss';
import { Back } from 'components/Back';

export const query = graphql`
  query {
    underline1: file(relativePath: { eq: "underline-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    underline2: file(relativePath: { eq: "underline-4.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    underline3: file(relativePath: { eq: "underline-3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    stegermeisterin: file(relativePath: { eq: "stegermeisterin.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
  }
`;

type DataProps = {
  stegermeisterin: GatsbyTypes.File;
  underline1: GatsbyTypes.File;
  underline2: GatsbyTypes.File;
  underline3: GatsbyTypes.File;
};

const Page: React.FC<PageProps<DataProps>> = (props) => {
  const { data } = props;

  return (
    <Layout>
      <main className="default">
        <section>
          <h1>
            Die{' '}
            <span className="relative">
              Stegermeisterin
              <GatsbyImage
                className="absolute w-42/40 -left-1 md:-left-2 top-1/3 -z-10 dark:opacity-40"
                image={data.underline1.childImageSharp!.gatsbyImageData}
                alt="Unterstrichen"
              />
            </span>
          </h1>
          <p>
            Bevor ich <strong>stegermeister®</strong> gegründet habe, bin ich in
            der öffentlich-rechtlichen Radiofamilie groß geworden. Unzählige
            Hörspiel- und Featureproduktionen sind durch meine Scherenhände
            gegangen in den Jahren als Audio Editorin. Als Regieassistentin und
            als Producerin im Hörspiel habe ich gelernt, wie man ein Skript zum
            Leben erweckt - mit nem Unter-Wasser-Hilfeschrei zum Beispiel
            (logisch!).
          </p>
          <p className="mt-12">
            Als selbständige Regisseurin und Autorin hab ich für verschiedene
            renommierte Radioanstalten gearbeitet. Meine Leidenschaft für kluge
            Audioerzählungen zwinge ich Studierenden des Fachs „sound and music
            production“ an der Hochschule Darmstadt auf. All das fließt zusammen
            bei <strong>stegermeister®</strong>.
          </p>
        </section>

        <section className="grid grid-cols-12 gap-4 lg:gap-8">
          <div className="relative col-span-12 -mx-3 xs:col-span-7 sm:col-span-8 xs:mx-0">
            <GatsbyImage
              imgClassName="rounded-2xl md:rounded-3xl"
              className="w-full overflow-hidden rounded-2xl shadow-profile xs:left-[-50%] xs:w-[150%] md:rounded-3xl"
              image={data.stegermeisterin.childImageSharp!.gatsbyImageData}
              alt="Hanna Steger"
            />
          </div>
          <div className="relative col-span-12 mt-8 text-sm max-w-prose xs:mt-0 xs:max-w-none xs:text-xs xs:col-span-5 sm:text-sm sm:col-span-4 xs:self-center lg:text-base">
            <div className="mb-2 text-2xl md:text-3xl md:mb-4">
              <strong className="relative">
                Hanna Steger
                <GatsbyImage
                  className="absolute rounded-r-sm w-24/20 -left-2 top-1/2 -z-10 rounded-x-sm dark:opacity-40"
                  image={data.underline2.childImageSharp!.gatsbyImageData}
                  alt="Unterstrichen"
                />
              </strong>
            </div>
            <div>
              Ist Silvester 1984 im Bauch ihrer Mutter über die innerdeutsche
              Grenze nach Westdeutschland geflüchtet.
            </div>
          </div>
        </section>

        <section id="idea">
          <h1>
            Du hast eine Idee?
            <br />
            <span className="relative">
              Klingt
              <GatsbyImage
                className="absolute w-42/40 -left-1 md:-left-2 top-1/2 -z-10 dark:opacity-40"
                image={data.underline3.childImageSharp!.gatsbyImageData}
                alt="Unterstrichen"
              />
            </span>{' '}
            großartig.
          </h1>
          <p>
            Jede gute Idee verdient es, gehört zu werden. Sprechen wir drüber.
          </p>
        </section>

        <section id="info">
          <a
            className="flex justify-start hover:text-dark hover:text-opacity-90 dark:hover:text-yellow"
            href={RouteEnum.START_PROJECT}
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="relative h1 mb-36">
              info@stegermeister.de{' '}
              <GatsbyImage
                className="absolute w-42/40 -left-1 md:-left-2 top-1/3 -z-10 dark:opacity-40"
                image={data.underline2.childImageSharp!.gatsbyImageData}
                alt="Unterstrichen"
              />
            </span>
          </a>
          <address>
            <strong>stegermeister®</strong>
            <br />
            Merlostraße 2<br />
            50668 Köln
            <br />
            <a href={RouteEnum.PHONE} rel="noopener noreferrer" target="_blank">
              +49 221 2984 6666
            </a>
          </address>
        </section>

        <section>
          <Back />
        </section>
      </main>
    </Layout>
  );
};

export const Head = () => {
  return <Meta />;
};

export default Page;
